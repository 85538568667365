$(document).ready(function () {
  window.directbusSuscribeToNewsletter = function (
    acreliaBaseUrl,
    acreliaKey,
    acreliaListId
  ) {
    let suscribeForm = $('#subscribe-form');

    const isFormValid = suscribeForm.valid();

    if (!isFormValid) {
      $('#error-toast-body').html(
        directbusTranslate(
          'Revisa los campos del formulario del que quieres copiar',
          lang
        )
      );
      $('#error-toast').toast('show');
      return;
    }

    let values = suscribeForm.serializeAssoc();

    if (values['s-email']) {
      let acreliaUrl =
        acreliaBaseUrl +
        `/lists/${acreliaListId}/contacts?token=${acreliaKey}&email_address=${values['s-email']}`;
      let editAcreliaUrl =
        acreliaBaseUrl +
        `/lists/${acreliaListId}/contacts/${values['s-email']}?token=${acreliaKey}&subscribe=1`;

      let data = {
        action: 'directbus_suscribe_to_newsletter',
        token: acreliaKey,
        email_address: values['s-email'],
        url: acreliaUrl,
        editUrl: editAcreliaUrl,
      };

      $.post(ajaxurl, data, function (res) {
        const resp = JSON.parse(res);
        console.log(resp);
        if (resp.error) {
          $('#error-toast-body').html(
            directbusTranslate(
              resp.message ? resp.message : resp.error,
              window.lang
            )
          );
          $('#error-toast').toast('show');
        } else {
          $('#success-toast-body').html(
            directbusTranslate(
              'Te has suscrito correctamente a la newsletter',
              lang
            )
          );
          $('#success-toast').toast('show');
        }
      });
    }
  };
});
